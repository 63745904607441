"use client";
import { GQLExternalLinkBlock, GQLInternalLinkBlock } from "graphql/generated-types";
import Link from "next/link";
import { useMedia, useLockBodyScroll } from "react-use";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import clsx from "clsx";
import UBOLogo from "@/svgs/ubo-logo.svg";
import { usePathname, useRouter, useParams } from "next/navigation";
import ChevronRight from "@/svgs/chevron-right.svg";
import { useSession } from "utils/hooks";

type NavProps = {
	menu: (GQLExternalLinkBlock[] | GQLInternalLinkBlock[]) | null;
	dashboardSlug: string;
	loggedIn: boolean;
	name: string;
};

const Nav: React.FC<NavProps> = ({ menu, dashboardSlug, loggedIn, name }) => {
	// const { data: dashboardSlug } = useGetDashboardSlugQuery();
	const isMobile = useMedia("(max-width: 1023px)", false);

	const path = usePathname();
	const params = useParams();
	// const [loggedIn, setLoggedIn] = useState(false);loggedIn
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useLockBodyScroll(isMenuOpen);
	const pathname = usePathname();

	useEffect(() => {
		if (isMenuOpen) {
			setIsMenuOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const isBackToDashboardButtonPath = ["rating", "profile", "quiz"].some((str) => pathname.includes(str));
	// Don't show if we're there
	const showIdeasLink = loggedIn && path !== "/" + dashboardSlug;

	const BackToDashboardButton = () => {
		if (isBackToDashboardButtonPath) {
			return (
				<Link
					href={`/idea/${params.ideaId}`}
					className="flex items-center gap-2 lg:gap-2.5 p-1 lg:p-2 px-3 lg:px-4 translate-y-1 lg:translate-y-2 rounded-full border-1 border-grey group"
				>
					<ChevronRight className="w-1.5 stroke-black rotate-180" />
					<span className="group-hover:underline underline-offset-2">Back to dashboard</span>
				</Link>
			);
		}
		return null;
	};

	return (
		<div className="flex items-end gap-10 -mb-0.5 leading-none">
			{loggedIn && !isMobile && <BackToDashboardButton />}
			{isMobile ? (
				<>
					<div className="grid justify-between h-full">
						<button
							className={clsx(
								"z-50",
								isMenuOpen && "text-beige",
								isBackToDashboardButtonPath ? "ml-auto -translate-y-0.5" : "place-self-end"
							)}
							onClick={() => setIsMenuOpen(!isMenuOpen)}
						>
							{isMenuOpen ? "Close" : "Menu"}
						</button>
						<BackToDashboardButton />
					</div>
					<MobileMenu isMenuOpen={isMenuOpen} menu={menu} loggedIn={loggedIn} />
				</>
			) : (
				<div className={clsx("flex flex-col items-end justify-between", !loggedIn && "h-full")}>
					{loggedIn && name ? (
						<div className="mb-3 text-sm italic">Welcome, {name}</div>
					) : (
						<Link
							href="/#get-started"
							scroll={false}
							className="flex items-center gap-5 font-bold link-underline lg:-mt-2"
						>
							Get Started
							<div className="rounded-full bg-purple h-[33px] w-[33px] grid place-items-center">
								<ChevronRight className="w-3 h-3 stroke-beige" />
							</div>
						</Link>
					)}

					<div className="flex gap-10">
						{showIdeasLink && (
							<Link href={`/${dashboardSlug}`} className="link-underline">
								Your other ideas
							</Link>
						)}
						{menu &&
							menu.map((item) => (
								<Link key={item?.id} href={item?.href} className="link-underline">
									{item?.text}
								</Link>
							))}

						<LoginButton loggedIn={loggedIn} />
					</div>
				</div>
			)}
		</div>
	);
};

export default Nav;

const LoginButton = ({ loggedIn }: { loggedIn: boolean }) => {
	const { logout } = useSession();
	const router = useRouter();
	if (loggedIn) {
		return (
			<button
				key="logout"
				className="text-left link-underline"
				onClick={async () => {
					await logout();
					router.push("/");
				}}
			>
				Log out
			</button>
		);
	} else {
		return (
			<Link key="login" href="/login" className="link-underline">
				Login
			</Link>
		);
	}
};

type MobileMenuProps = {
	isMenuOpen: boolean;
	menu: GQLInternalLinkBlock[] | GQLExternalLinkBlock[] | null;
	loggedIn: boolean;
};

const MobileMenu = ({ isMenuOpen, menu, loggedIn }: MobileMenuProps) => {
	return (
		<motion.nav
			initial={{ x: "-100%" }}
			animate={{ x: isMenuOpen ? 0 : "-100%" }}
			exit={{ x: "-100%" }}
			transition={{ duration: 0.3 }}
			className="fixed top-0 left-0 z-40 w-full h-full bg-charcoal text-beige"
		>
			<div className="container">
				<div className="flex justify-between py-6 border-b-1 border-light">
					<Link href="/">
						<UBOLogo className="w-32 lg:w-48 fill-beige" />
						<h1 className="sr-only">Unlocking Business Opportunities</h1>
					</Link>
				</div>
			</div>
			<hr className="w-screen opacity-10 bg-hr -z-10 mt-[-1px]" />
			<div className="container">
				<div className="flex flex-col h-full my-16 leading-normal text-36px">
					{loggedIn && <Link href="/dashboard">Dashboard</Link>}
					{menu?.map((item: GQLInternalLinkBlock | GQLExternalLinkBlock) => (
						<Link key={item?.id} href={item?.href}>
							{item?.text}
						</Link>
					))}
					<LoginButton loggedIn={loggedIn} />
				</div>
				<div className="flex flex-col gap-4 text-24px">
					<div className="text-16px">We&apos;re here to help</div>
					<Link href="mailto:ask@business.tas.gov.au">ask@business.tas.gov.au</Link>
					<a href="tel:1800 440 026">1800 440 026</a>
				</div>
			</div>
		</motion.nav>
	);
};
